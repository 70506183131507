import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import logoDark from './logo.svg';
import menu from './menu-icn.svg';
import Menu from './Menu';


const Header = ({ isDarkBackground }) => {

  const logoSrc = isDarkBackground ? logoDark : logoDark ;
  const location = useLocation();



  const [isOpen, setIsOpen] = useState(false);


    

    const openMenu = () => {

        document.body.style.overflow = 'hidden'; 
    };

    const closeMenu = () => {

      document.body.style.overflow = 'auto'; 
    };

    // const openServicesMenu = () => {
    //   setIsMenuServicesOpen(true);
    //   document.body.style.overflow = 'hidden';
    // };

    // const closeServicesMenu = () => {
    //   setIsMenuServicesOpen(false);
    //   document.body.style.overflow = 'auto';

    // };

    // const openWorksMenu = () => {
    //   setisMenuWorksOpen(true);
    //   document.body.style.overflow = 'hidden';
    // };

    // const closeWorksMenu = () => {
    //   setisMenuWorksOpen(false);
    //   document.body.style.overflow = 'auto';

    // };





  return (
    
      <div className='header'>
        <div className='menu'>
        {isOpen && <Menu isDarkBackground={true} setIsMenuOpen={setIsOpen} />}
        </div>
          <nav className="navbar navbar-expand-lg px-sm-4">
            <div className="container-fluid d-flex align-items-start">

              {/* Logo on the left */}
              <a className="d-none d-lg-block logo-container py-sm-3" href="/">
                <img src={logoDark} alt="Logo" width="90px" height="auto" />
              </a>
              

              <a className="d-flex d-lg-none logo-container" href="/">
                <img src={logoDark} alt="Logo" width="50px" height="auto" />
              </a>

              <div className="menu-icon-container d-lg-block my-3">
                <img onClick={() => { setIsOpen(!isOpen);}} src={menu} alt="menu" width="30" height="auto" />
              </div>


              


            </div>
          </nav>
        </div>
      );
    }
  
  export default Header;
  