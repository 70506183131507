import React, { useState, useEffect, useRef } from 'react';
import Header from '../../shared/Header';

import VideoLoader from '../../functions/VideoURLLoader';
import arrowIcn from '../../assets/icns/arrow.svg'; 



import geox01 from '../../assets/imgs/geoxITC/geoxITC_01.jpg';
import geox02 from '../../assets/imgs/geoxITC/geoxITC_02.jpg';
import geox03 from '../../assets/imgs/geoxITC/geoxITC_03.jpg';


import MainArrows from '../../shared/MainArrows';



const GeoxITCPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  const videoList = [
    { folderName: 'geox', fileName: 'geox_demo01.mp4' },
    { folderName: 'geox', fileName: 'geox-hero.mp4' },
];



  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRefs = useRef([]);

  useEffect(() => {
      videoRefs.current.forEach(video => {
          if (video) {
              video.load();
          }
      });
  }, []);


    



  return (
    <div className='p-2'>

      <MainArrows pageIndex={(1)}/>
        
        
        <div className='row px-4'>
          <div className='col-sm-2'></div>
          <div className='col-sm col-xs-12'>
            <div className='row mt-4'>
              <div className='col-10'>
                <h4 className='fw-300 fs-28 capitalize'>geox video booth</h4>
                    <h4 className='fs-60 fw-600'>
                    A video created to present Geox products to the attendees at ITC Las Vegas 2024.
                    </h4>
              </div>


            <div className='col-12 mt-5'>
                <VideoLoader video={videoList[1]} videoControl="" videoAutoplay="autoPlay" videoMuted="muted" videoLoop="loop" videoWidth="100%" />

                </div>
            </div>



 



        <div className='row'>
            <div className='col-6 mt-4'>
                <img
                  src={geox01}
                  width='100%'
                />
            </div>
            <div className='col-6 mt-4'>
                <img
                  src={geox02}
                  width='100%'
                />
            </div>
            <div className='col-6 mt-4'>
                <img
                  src={geox03}
                  width='100%'
                />
            </div>


        

            </div>
            </div> 

  
        <div className='mx-4 px-4'>
        <div className='row mt-4'>
        <div className='col-sm-10 col-xs-12 py-5'>


            <div className='py-3'></div>

              <div className='col-12'>
                <p className='fs-18 fw-400'>This work includes</p>
                  <div className='row'>
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Capabilities</h4>
                        <ul className='p-0'>
                          <li className='py-1'>Web Animation</li>
                          <li className='py-1'>Product Design</li>
                          <li className='py-1'>UX/UI Review</li>
                          <li className='py-1'>Design System</li>
                        </ul>
                    </div>
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Softwares</h4>
                        <ul className='p-0'>
                          <li className='py-1'>After Effect</li>
                          <li className='py-1'>Figma</li>
                          <li className='py-1'>Illustrator</li>
                          <li className='py-1'>Lottie</li>
                        </ul>
                    </div>
                    
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Cooperation</h4>
                        <ul className='p-0'>
                          <li className='py-1'>Project</li>

                        </ul>
                    </div>

                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Industry</h4>
                          <li className='py-1'>SaaS Healthcare</li>
                          <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                          <li className='py-1'>2 Months, ongoing</li>

                    </div>

                  </div>


                  <div className='m-3'></div>

                  <div className='d-flex'>
                    <h4 className='fs-18 fw-600'>Client: Navina</h4>
                    <a className='px-2' href='https://navina.ai' target='blank'><h4 className='fs-18 fw-400 px-4'>Visit Website &nbsp;↗</h4></a>

                  </div>

                  </div>
              </div>
          </div>
      </div>

      </div>

      </div>



  );
};

export default GeoxITCPage;
