import React, { useState, useEffect, useRef } from 'react';
import Header from '../../shared/Header';

import VideoLoader from '../../functions/VideoURLLoader';
import arrowIcn from '../../assets/icns/arrow.svg'; 


import navina01 from '../../assets/imgs/navina/navina01.png'; 
import navina02 from '../../assets/imgs/navina/navina02.png'; 
import navina03 from '../../assets/imgs/navina/navina03.png'; 
import navina04 from '../../assets/imgs/navina/navina04.png'; 
import MainArrows from '../../shared/MainArrows';



const NavinaPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 


  const videoList = [
    { folderName: 'navina', fileName: 'navina-mackbook-screen01.mp4' },
    { folderName: 'navina', fileName: 'navina_mockups0001.mp4' },

  ];

  const videoListScroll = [
    { folderName: 'navina', fileName: 'navina_mockups0002.mp4' },
    { folderName: 'navina', fileName: 'navina_mockups0003.mp4' },
    { folderName: 'navina', fileName: 'navina_mockups0004.mp4' },
    { folderName: 'navina', fileName: 'navina_mockups0005.mp4' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRefs = useRef([]);

  useEffect(() => {
      videoRefs.current.forEach(video => {
          if (video) {
              video.load();
          }
      });
  }, []);


    
  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? videoListScroll.length - 1 : prevIndex - 1));
};

const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === videoListScroll.length - 1 ? 0 : prevIndex + 1));
};





  return (
    <div className='p-2'>

      <MainArrows pageIndex={(1)}/>
        
        
        <div className='row px-4'>
          <div className='col-sm-2'></div>
          <div className='col-sm col-xs-12'>
            <div className='row mt-4'>
              <div className='col-10'>
                <h4 className='fw-300 fs-28 capitalize'>Website Animations</h4>
                    <h4 className='fs-60 fw-600'>
                      Enhancing Navina's Website Experience Through Web Animations
                    </h4>
              </div>
            </div>
              <img className='pt-5 img-fluid' src={navina01} alt=""/>              
          </div>
        </div> 


      <div className='mx-4 px-4'>
        <div className='row'>
          <div className='col py-5'>
            <div className='d-flex'>
              <p className='fs-24 fw-400'>
                  Navina ends frustrating EHR data hunting with an actionable Patient Portrait that allows physicians to review any case in minutes. Navina empowers physicians to be more prepared for visits and creates space for meaningful patient interactions.
              </p>
            </div>
          </div>
        </div>
      </div>



        <div className='row m-0 px-5'>
            <div className='col-6 p-2'>
                <img
                  src={navina02}
                  width='100%'
                />
            </div>
            <div className='col-6 p-2'>
                <img
                  src={navina03}
                  width='100%'
                />
            </div>
            <div className='col-6 p-2'>
                <img
                  src={navina04}
                  width='100%'
                />
            </div>
        <div className='row px-3'>
            <div className='col-12'>
                <div className='row'>
                <VideoLoader video={videoList[1]} videoControl="" videoAutoplay="autoPlay" videoMuted="muted" videoLoop="loop" videoWidth="100%" />
                </div>
            </div>
        </div>




          <div className='row my-5 px-sm-4'>
            <div className='d-flex mb-5'>
              <p className='fs-24 fw-400'>
                This series of animations was made for Navina's new website and aims to demonstrate the set of tools provided by Navina's product. For each solution tool, we designed a set of animations that will give a basic idea of the work experience with Navina and react to a call to action for medical teams and healthcare providers who want to try the entire product. The animations below are just a few examples from the 25 animations that were made for Navina's website.
              </p>
            </div>
          </div>

        </div>

        



        <div className='row px-5 m-0'>
            <div className='position-relative'>
                <div className="slider">
                  <button className="slider-button prev" onClick={prevSlide}>
                      <img src={arrowIcn} className='rotate-180' width={'35px'} alt="Previous" />
                    </button>
                    <div className="slider-video">
                    <div className='col-12 my-5 video'>
                          <VideoLoader video={videoListScroll[currentIndex]} videoControl="" videoAutoplay="autoPlay" videoMuted="muted" videoLoop="loop" videoWidth="100%"/>
                      </div>
                    </div>

                    <button className="slider-button next" onClick={nextSlide}>
                      <img src={arrowIcn} width={'35px'}  alt="Next" /> 
                    </button>
                </div>
              </div>
            </div>
            
  
        <div className='mx-4 px-4'>
        <div className='row mt-4'>
        <div className='col-sm-10 col-xs-12 py-5'>


            <div className='py-3'></div>

              <div className='col-12'>
                <p className='fs-18 fw-400'>This work includes</p>
                  <div className='row'>
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Capabilities</h4>
                        <ul className='p-0'>
                          <li className='py-1'>Web Animation</li>
                          <li className='py-1'>Product Design</li>
                          <li className='py-1'>UX/UI Review</li>
                          <li className='py-1'>Design System</li>
                        </ul>
                    </div>
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Softwares</h4>
                        <ul className='p-0'>
                          <li className='py-1'>After Effect</li>
                          <li className='py-1'>Figma</li>
                          <li className='py-1'>Illustrator</li>
                          <li className='py-1'>Lottie</li>
                        </ul>
                    </div>
                    
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Cooperation</h4>
                        <ul className='p-0'>
                          <li className='py-1'>Project</li>

                        </ul>
                    </div>

                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Industry</h4>
                          <li className='py-1'>SaaS Healthcare</li>
                          <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                          <li className='py-1'>2 Months, ongoing</li>

                    </div>

                  </div>


                  <div className='m-3'></div>

                  <div className='d-flex'>
                    <h4 className='fs-18 fw-600'>Client: Navina</h4>
                    <a className='px-2' href='https://navina.ai' target='blank'><h4 className='fs-18 fw-400 px-4'>Visit Website &nbsp;↗</h4></a>

                  </div>

                  </div>
              </div>
          </div>
      </div>
  </div>


  );
};

export default NavinaPage;
