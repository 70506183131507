import React, { useState, useEffect } from 'react';
import Header from '../../shared/Header';

import VideoLoader from '../../functions/VideoURLLoader';


import otterize01 from '../../assets/imgs/otterize/otterize-panel-img.gif'; 
import otterize02 from '../../assets/imgs/otterize/otterize-cubes-img.png'; 
import otterize03 from '../../assets/imgs/otterize/otterize-service-panel-img.gif'; 
import otterize04 from '../../assets/imgs/otterize/otterize-service-panel-lines.png'; 
import otterize05 from '../../assets/imgs/otterize/otterize-service-panel-transparent.png'; 
import otterize06 from '../../assets/imgs/otterize/otterize-storyboard-img.png'; 
import otterize07 from '../../assets/imgs/otterize/otterize-cube-logo-img.png'; 
import MainArrows from '../../shared/MainArrows';



const OtterizePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  const videoList = [
    { folderName: 'otterize', fileName: 'Otterize.mp4' },
  ];


  return (
    <div className='p-2'>
    <MainArrows pageIndex={(3)}/>
      
      
    <div className='row px-4'>
        <div className='col-sm-2'></div>
        <div className='col-sm col-xs-12'>
            <div className='row mt-4'>
        <h4 className='fw-300 fs-28 capitalize'>Animated Explainer | Brand Design</h4>
           <h4 className='fs-60 fw-600'>
           Otterize is a platform for automating workload IAM for workloads on Kubernetes                    
           </h4>


           <div className='col-12 my-5'>
                  <VideoLoader video={videoList[0]} videoControl="controls" videoAutoplay="" videoMuted="" videoLoop="" videoWidth="100%"/>
              </div>


        </div> 
      </div>
      </div>




 



        <div className='mx-4 px-4'>
        <div className='row mt-4 px-5'>
        <div className='col-sm-10 col-xs-12 py-5'>
          <div className='d-flex'>
            <p className='fs-24 fw-400'>

              Otterize's mission is to simplify authorization by freeing up precious time so developers, security, and platform teams can focus on what they do best—creating killer products and delivering top-notch user experiences.
              Otterize made it easy and unified for all kinds of service-to-service communication.
            </p>
          </div>


              </div>
          </div>
      </div>


      <div className='mx-4 px-4'>
        <div className='row mt-4 px-5'>
        <div className='col-12 mt-4'>
          <div className=''>
            <h5 className='pb-4 fw-600 fs-20'>Storyboard</h5>
            <p className='fs-24 fw-400'>

            </p>
          </div>
          </div>
            <div className='col-12 m-0 p-0'>
              <img className='img-fluid' src={otterize06} alt=""/>
                </div>
          </div>
      </div>



      <div className='mx-4 px-4'>
        <div className='row mt-4 px-5'>
          <div className='col-sm-10 col-xs-12 py-5'>
              <div className=''>
                <h5 className='pb-4 fw-600 fs-20'>Design</h5>
                    <p className='fs-24 fw-400'>

                    </p>
                </div>
              </div>
          </div>
      </div>






        <div className='row m-0 px-4 '>
        <div className='col col-xs-12 m-0 p-0'>
        </div>
            <div className='col-md col-sm-12 m-0 p-0'>
                <img
                  className='p-2'
                  src={otterize03}
                  width='100%'
                />
            </div>
            <div className='col-md col-sm-12 m-0 p-0'>
                <img
                  className='p-2'
                  src={otterize04}
                  width='100%'
                />
            </div>
            <div className='col-sm-12 col-xs-12'></div>
            <div className='col-md col-sm-12 m-0 p-0'>
                <img
                  className='p-2'
                  src={otterize05}
                  width='100%'
                />
            </div>
            <div className='col-md col-sm-12 m-0 p-0'>
                <img
                  className='p-2'
                  src={otterize05}
                  width='100%'
                />
            </div>
            <div className='col-md col-sm-12 m-0 p-0'>
                <img
                  className='p-2'
                  src={otterize02}
                  width='100%'
                />
            </div>

            <img className='pt-5 img-fluid' src={otterize01} alt=""/>

          </div>





    <div className='mx-4 px-4'>
      <div className='row mt-4'>
        <div className='col-sm-12 col-xs-12 py-5'>
              <div className='col-12'>
                <p className='fs-18 fw-400'>This work includes</p>
                  <div className='row'>
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Capabilities</h4>
                        <ul className='p-0'>
                          <li className='py-1'>3D Aniamtion</li>
                          <li className='py-1'>Concept Design</li>
                        </ul>
                    </div>
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Softwares</h4>
                        <ul className='p-0'>
                          <li className='py-1'>Maya</li>
                          <li className='py-1'>After Effect</li>
                          <li className='py-1'>Illustrator</li>
                          <li className='py-1'>Photoshop</li>
                        </ul>
                    </div>
                    
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Cooperation</h4>
                        <ul className='p-0'>
                          <li className='py-1'>Retainer</li>

                        </ul>
                    </div>

                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Industry</h4>
                          <li className='py-1'>Technology</li>
                          <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                          <li className='py-1'>1 Month</li>

                    </div>

                  </div>


                  <div className='mt-4'>
                    <div className='d-flex'>
                      <h4 className='fs-18 fw-600'>Client: Otterize</h4>
                        <a className='px-2' href='https://otterize.com' target='blank'><h4 className='fs-18 fw-400 px-4'>Visit Website &nbsp;↗</h4></a>
                    </div>
                  </div>
                  <div className='m-4'></div>


                  </div>
              </div>
          </div>
      </div>
  </div>


  );
};

export default OtterizePage;


