import React, { useState, useEffect } from 'react';
import Header from '../shared/Header';
import paz from '../assets/imgs/paz.png'

import linkedin from '../assets/icns/linkedin.svg'
import whatsapp from '../assets/icns/whatsapp.svg'

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 


  return (
    <div className='container-fluid h-100'>
      <div className="row mt-sm-4">
        <div className='col-sm-2 col-xs-12'></div>
        <div className='col-sm col-xs-12'>
      <div className='container'>
         <div className='row d-flex align-items-start pe-4'>
            <div className='col-md-9 col-xs-12 p-4'>

                <h2 className='mb-4 fs-24 fw-600'>
                Hey. I'm Paz, an Interdisciplinary creator, animator, and entrepreneur with over ten years of experience producing animation, human-computer interactions, and full-stack development for tech, healthcare, and TV industries.
                </h2>

                <p className='fs-22 fw-400 pb-2'>
                I specialize in creating 3D animated movies and developing web interfaces that involve art and code. I have a BFA in Animation and Computer Engineering and an MA in HCI from well-known institutes in Israel.
                I'm energized when I open my 3D software and start modeling my virtual worlds.
                </p>

                <p className='fs-22 fw-400 pb-2'>
                I was born to farmer parents in a small town in the north of Israel. I spent lots of time learning and playing computer games during my school days, mainly because my classmates bullied me. My life mission is to create movies that will tell my life experiences.
                </p>

                <p className='fs-22 fw-400'>
                I want to become a lovable, well-known, awarded animator who makes meaningful, innovative, and fun movies that people love. I like the sea, watching the waves, and water sports activities are my thing.
                </p>

              </div>

                <div className='col p-4'>
                  <img className='img-fluid border-bottom-dark' src={paz} alt="profile img"/>
                  <div className='row mt-2'>
                  <div className='col-12 d-flex'>
                    <a href="https://www.linkedin.com/in/pazroth/" target='blank'>
                      <img className='w-30 me-2 pointer' src={linkedin} alt="icn"/>
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=972528450488" target='blank'>
                    <img className='w-30 pointer' src={whatsapp} alt="icn"/>
                    </a>

                  </div>
                </div>
              </div>
              </div>
         </div>
      </div>
    </div>
    </div>
  );
};

export default AboutPage;
