import React, { useEffect, useState } from 'react';
import AWS from 'aws-sdk';

const VideoPlayer = ({ videoKey, videoautoplay, controls, styleguide }) => {
  const [videoUrl, setVideoUrl] = useState('');


  useEffect(() => {
    console.log('Fetching video from S3...');
    AWS.config.update({
      accessKeyId: 'AKIA4KFQMUUYB3HOPKUQ',
      secretAccessKey: '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',
      region: 'eu-west-3', 
    });

    const s3 = new AWS.S3();
    const bucketName = 'paz-roth-videos';

    const params = {
      Bucket: bucketName,
      Key: videoKey,

    };

    s3.getSignedUrl('getObject', params, (err, url) => {
        if (err) {
          console.error('Error generating pre-signed URL:', err);
        } else {
          setVideoUrl(url);
        }
      });
    }, [videoKey]);

  return (
    <div className={styleguide}>
      {videoUrl ? (
        <video className='p-0'  controls={controls} autoPlay={videoautoplay} loop muted>
        <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <p>Loading video...</p>
      )}
    </div>
  );
};


export default VideoPlayer;

