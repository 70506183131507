import React, { useState, useEffect } from 'react';
import Header from '../../shared/Header';

import VideoLoader from '../../functions/VideoURLLoader';

import arrows00 from '../../assets/imgs/arrows/arrows00.png'; 
import arrows01 from '../../assets/imgs/arrows/arrows-animator-unity.png'; 
import arrows02 from '../../assets/imgs/arrows/arrows-avatar-sketch.gif'; 
import arrows03 from '../../assets/imgs/arrows/arrows-lips-heads.png'; 
import MainArrows from '../../shared/MainArrows';




const ArrowsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 


  const videoList = [
    { folderName: 'arrows', fileName: 'arrows-interface.mp4' },
    { folderName: 'arrows', fileName: 'arrows-controls-video_900x900.mp4' },

    

  ];


  const [width, setWidth] = useState(560); 
  const height = width * (9 / 16); 


  return (
    <div className=' p-2'>
      <div className="row">
      <MainArrows pageIndex={(2)}/>
        
        
        <div className='row px-4'>
        <div className='col-sm-2'></div>
        <div className='col-sm col-xs-12'>
            <div className='row mt-4'>
              <div className='col-10'>

                <h4 className='fw-300 fs-28 capitalize'>Game, UI and Dev</h4>

                    <h4 className='fs-60 fw-600 capitalize pe-md-5'>
                    Avatar Coach For Social Skill Interactions Using 3D Avatar, Game Engine And Web App
                    </h4>
            </div>     
          </div>
          <img className='pt-5 img-fluid' src={arrows00} alt=""/>

        </div> 


      <div className='mx-4 px-4'>
        <div className='row'>
          <div className='col py-5'>
              <p className='fs-22 fw-400'>
                Introducing Arrows, innovative Skill Coach - A SaaS platform for learning and practicing social skills through realistic conversations with avatars based on Generative AI,  grounded in a leading evidence-based program, empowering social skills.
              </p>
          </div>
        </div>
      </div>



      <div className='row m-0 px-5'>
          <div className='col-12 mt-4'>
                <VideoLoader video={videoList[0]} videoControl="" videoAutoplay="autoPlay" videoMuted="muted" videoLoop="loop" videoWidth="100%"/>
              </div>
          </div>




          <div className='row m-0 pt-4'>
            <div className='col'>
                  <img
                    className=''
                    src={arrows02}
                    width='100%'
                  />
              </div>
              <div className='col'>
                  <img
                  className=''
                    src={arrows01}
                    width='100%'
                  />
              </div>
              <div className='col-12 pt-4'>
                  <img
                    src={arrows03}
                    width='100%'
                  />
            </div>
          </div>
        </div>






{/* 
        <div className='row px-3'>
            <div className='col-12'>
                <div className='row'>
                <VideoPlayer videoKey="mockups0001.mp4" videoautoplay="autoplay" styleguide='row col-12 p-0 m-0'/>
                </div>
            </div>
        </div> */}






  
      <div className='mx-4 px-4'>
        <div className='row'>
          <div className='col py-5'>
            <p className='fs-22 fw-400'>

            Arrows' team requested help creating their platform's first-ever human-avatar interaction. To fulfill this request, we started by defining the experience and creating a wireframe with all the details the avatar should include to help develop social skills.

            The project's pipeline started with modeling the selected character [based on ready-made modeling]. Then, I created an entire facial rig for animating movements and reactions to make the avatar feel 'alive.' The last part of the design was to make the lips sync, which was created using a set of blend-shape designs.

            Having successfully completed the design and animation phase, we ventured into the development side. Leveraging the power of Unity game-engine and C# scripts, we amalgamated all the elements to create an Avatar model that not only received an audio source but also spoke in sync with it. The model's dynamic emotions and animations, responding to the conversation pattern, were a great proof of concept for Arrows interface for getting the next fund phase.


            </p>
          </div>
            <iframe
                width="100%"
                height={height}
                src={`https://www.youtube.com/embed/wohVFqqRXzI`}
                title="Arrows demo video"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>


                </div>
              </div>


    <div className='mx-4 px-4'>
      <div className='row mt-4'>
        <div className='col-12 py-5'>
            <div className='col-12'>
                <p className='fs-18 fw-400'>This work includes</p>
                  <div className='row'>
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Capabilities</h4>
                        <ul className='p-0'>
                          <li className='py-1'>3D Modeling</li>
                          <li className='py-1'>3D Aniamtion</li>
                          <li className='py-1'>Game Engine</li>
                          <li className='py-1'>React JS</li>
                          <li className='py-1'>webGL</li>
                          <li className='py-1'>C#</li>


                        </ul>
                    </div>
                    
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Softwares</h4>
                        <ul className='p-0'>
                          <li className='py-1'>Maya</li>
                          <li className='py-1'>Unity</li>
                          <li className='py-1'>VS Code</li>
                          <li className='py-1'>AWS</li>

                        </ul>
                    </div>

                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Cooperation</h4>
                        <ul className='p-0'>
                          <li className='py-1'>Project</li>

                        </ul>
                    </div>

                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Industry</h4>
                          <li className='py-1'>Education, Well-being</li>
                          <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                          <li className='py-1'>4 Months</li>

                    </div>

                  </div>

                  <div className='m-3'></div>

                  <div className='d-flex'>
                    <h4 className='fs-18 fw-600'>Client: Arrows Team</h4>
                    <a className='px-2' href='https://ar-rows.com' target='blank'><h4 className='fs-18 fw-400 px-4'>Visit Website &nbsp;↗</h4></a>

                  </div>

                   


                  </div>
              </div>
          </div>
      </div>
  </div>
  </div>

  );
};

export default ArrowsPage;
