import React, { useState, useEffect } from 'react';
import Header from '../../shared/Header';
import VideoPlayer from './VideoCTRL';

import komodor01 from '../../assets/imgs/komodor/komodor-booth-plain.png'; 
import komodor02 from '../../assets/imgs/komodor/komodor-turtle-one.png'; 
import komodor03 from '../../assets/imgs/komodor/komodor-turtle-two.png'; 
import komodor04 from '../../assets/imgs/komodor/komodor-turtle-three.png'; 
import komodor05 from '../../assets/imgs/komodor/komodor-booth-demo.png'; 
import komodor06 from '../../assets/imgs/komodor/komodor-turtles-icons.png'; 
import MainArrows from '../../shared/MainArrows';




const KomodorPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (

    <div className='container-fluid p-2'>
    <MainArrows pageIndex={(10)}/>
      
      
      
    <div className='row px-4'>
        <div className='col-sm-2'></div>
        <div className='col-sm col-xs-12'>
        <div className='row mt-4'>
        <h4 className='fw-300 fs-28 capitalize'>
        Website Animations
        </h4>
        <h4 className='fs-60 fw-600'>
        Interactive Booth design for komodor kubecon 2024 - K8s Mutant Ninja Turtles
           </h4>

           <p className='fs-24 fw-400'>
           For the 2024 Kubecon, Komodor wanted to stand out in the conference with a unique style. We came up with the idea of the Ninja Turtles for that request. The booth design included a shot of the 3D turtle characters troubleshooting in the Kubernetes environment.

           </p>
      </div> 
      <img className='pt-5 img-fluid' src={komodor01} alt=""/>




  <div className='mx-4 px-4'>
    <div className='row'>
      <div className='col py-5'>
        <div className='d-flex'>
          <p className='fs-22 fw-400'>
          The booth got a space for a screen that presented Komodor's Ninjas demonstrating the platform while doing up to 10sec animation to catch the crowd's attention looking at the booth. Btw, Komodor's team also wears turtles shield on their backs.

          </p>
        </div>
      </div>
    </div>
  </div>



    <div className='row m-0 px-5'>
        <div className='col p-2'>
            <img
              src={komodor02}
              width='100%'
            />
        </div>
        <div className='col p-2'>
            <img
              src={komodor03}
              width='100%'
            />
        </div>
        <div className='col p-2'>
            <img
              src={komodor04}
              width='100%'
            />
        </div>
        <div className='col-12 p-2'>
            <img
              src={komodor05}
              width='100%'
            />
        </div>
      </div>




{/* 



  <div className='row px-5 m-0'>
      <div className='position-relative'>
            <div className='row position-absolute top-50 translate-middle-y w-100'>
              <div className='col-6 d-flex justify-content-start'>
                <img
                  className='p-3 rotate-180 pointer'
                  onClick={prevVideo}
                  src={arrowIcn}
                  width='20%'
                  alt='Previous'
                />
              </div>
              <div className='col-6 d-flex justify-content-end'>
                <img
                  className='p-3 pointer'
                  onClick={nextVideo}
                  src={arrowIcn}
                  width='20%'
                  alt='Next'
                />
              </div>
            </div>

            <div className='col-12'>
                    <video
                      className="video"
                      src={getVideoUrl(videoNames[currentVideoIndex])}
                      autoPlay='autoplay'
                      muted
                      loop
                      style={{ maxWidth: '100%' }}
                    />
                  </div>
        </div>
    </div> */}




    <div className='mx-4 px-4'>
    <div className='row mt-4'>
    <div className='col-sm-10 col-xs-12 py-5'>


        <div className='py-3'></div>

          <div className='col-12'>
            <p className='fs-18 fw-400'>This work includes</p>
              <div className='row'>
                <div className='col'>
                  <h4 className='fs-18 fw-600'>Capabilities</h4>
                    <ul className='p-0'>
                      <li className='py-1'>Booth Design</li>
                      <li className='py-1'>Branding</li>
                      <li className='py-1'>3D Aniamtion</li>
                      <li className='py-1'>Explainer Video</li>
                    </ul>
                </div>
                <div className='col'>
                  <h4 className='fs-18 fw-600'>Softwares</h4>
                    <ul className='p-0'>
                      <li className='py-1'>After Effect</li>
                      <li className='py-1'>Maya</li>
                      <li className='py-1'>Illustrator</li>
                      <li className='py-1'>Photoshop</li>
                    </ul>
                </div>
                
                <div className='col'>
                  <h4 className='fs-18 fw-600'>Cooperation</h4>
                    <ul className='p-0'>
                      <li className='py-1'>Project</li>

                    </ul>
                </div>

                <div className='col'>
                  <h4 className='fs-18 fw-600'>Industry</h4>
                      <li className='py-1'>SaaS Healthcare</li>
                      <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                      <li className='py-1'>2 Months, ongoing</li>

                </div>

              </div>


              <div className='m-3'></div>

              <div className='d-flex'>
                <h4 className='fs-18 fw-600'>Client: Komodor</h4>
                <a className='px-2' href='https://komodor.com' target='blank'><h4 className='fs-18 fw-400 px-4'>Visit Website &nbsp;↗</h4></a>

              </div>

              </div>
          </div>
      </div>
  </div>
</div>
</div>
</div>

  );
};

export default KomodorPage;
