import React, { useState, useEffect } from 'react';
import Header from '../../shared/Header';

import cottage01 from '../../assets/imgs/cottage/cottage01.jpg'; 
import cottage02 from '../../assets/imgs/cottage/cottage02.png'; 
import cottage03 from '../../assets/imgs/cottage/cottage03.png'; 
import MainArrows from '../../shared/MainArrows';


const CottagePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  const [width, setWidth] = useState(560); 
  const height = width * (9 / 16); 



  return (
    <div className=' p-2'>
    <MainArrows pageIndex={(5)}/>
      
      
      
    <div className='row px-4'>
        <div className='col-sm-2'></div>
        <div className='col-sm col-xs-12'>
        <div className='row mt-4'>
        <h4 className='fw-300 fs-28 capitalize'>
          Research Prototype, SnapChat and the BBC
        </h4>
           <h4 className='fs-60 fw-600'>
           An augmented smart home expert, affecting the physical world                  
           </h4>

      </div> 
      </div>
      </div>






    <div className='mx-4 px-4'>
    <div className='row mt-4'>
    <div className='col-sm-12 col-xs-12 py-5'>
        <p className='fs-24 fw-400'>
            This project was created as part of the Snap Creative Challenge - Reimagine the Future of Storytelling With Augmented Reality, and presented at IMX '20: ACM International Conference on Interactive Media Experiences.
            Cottage is an augmented smart home expert which can control physical connected objects in the house. By exposing hidden processes in the smart home as part of a story, and making the smart home manager into a persona which cares for the user's wellbeing, we aim to lower alienation and enhance the sense of control of smart home users.
          </p>
        </div>
    </div>
</div>



    <div className='row m-0 px-4 '>
        <div className='col-md-10 col-sm-12 m-0 p-0'>
            <img
              className='p-2'
              src={cottage01}
              width='100%'
            />
        </div>
        <div className='col col-xs-12 m-0 p-0'>
      </div>
        <div className='col-12-md col-sm-12 m-0 p-0'>
            <img
              className='p-2'
              src={cottage02}
              width='100%'
            />
        </div>



            <div className='row mt-4'>
            <div className='col-sm-12 col-xs-12 py-5'>
                  <p className='fs-24 fw-400'>
                    By designing Cottage, we explore how AR objects could interface with the real world and demonstrate how such interaction could enhance the sense of presence and invite users into the mixed reality.
                    You can check out the full presentation video here.
                    And you can try out our Proof-of-Concept app here (iOS and HomeKit required)
                  </p>
                </div>
            </div>



        {/* <img className='pt-5 img-fluid' src={otterize01} alt=""/> */}
        <iframe
                width="100%"
                height={height}
                src={`https://www.youtube.com/embed/pfhNU9hFrGU`}
                title="cottage demo"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>





      </div>





<div className='mx-4 px-4'>
  <div className='row mt-4'>
    <div className='col-sm-12 col-xs-12 py-5'>
          <div className='col-12'>
            <p className='fs-18 fw-400'>This work includes</p>
              <div className='row'>
                <div className='col'>
                  <h4 className='fs-18 fw-600'>Capabilities</h4>
                    <ul className='p-0'>
                      <li className='py-1'>HCI Research</li>
                      <li className='py-1'>Prototyping</li>
                      <li className='py-1'>3D Aniamtion</li>
                      <li className='py-1'>Product Design</li>
                      <li className='py-1'>App Design</li>
                    </ul>
                </div>
                <div className='col'>
                  <h4 className='fs-18 fw-600'>Softwares</h4>
                    <ul className='p-0'>
                      <li className='py-1'>Unity AR</li>
                      <li className='py-1'>Xcode</li>
                      <li className='py-1'>Maya</li>
                      <li className='py-1'>After Effect</li>
                      <li className='py-1'>Illustrator</li>
                      <li className='py-1'>Photoshop</li>

                    </ul>
                </div>
                
                <div className='col'>
                  <h4 className='fs-18 fw-600'>Cooperation</h4>
                    <ul className='p-0'>
                      <li className='py-1'>Research Project</li>
                      <h4 className='fs-18 fw-600 pt-2'>Industry</h4>
                      <li className='py-1'>Technology</li>
                    </ul>
                </div>

                <div className='col'>
                      <h4 className='fs-18 fw-600'>Duration</h4>
                      <li className='py-1'>6 Months</li>
                      <h4 className='fs-18 fw-600 pt-2'>Partners</h4>
                      <li className='py-1'>BBC</li>
                      <li className='py-1'>Snap inc</li>

                </div>

              </div>


              <div className='mt-4'>
                <div className='pt-4'>

                  <h4 className='fs-18 fw-400'>
                  <span className='fw-400'> Team:</span> Paz Roth, Daphna Abulafia, Shira Czerninski, Adi Gilad, Udi Lumnitz, Dan Pollak
                  </h4>

                  <h4 className='fs-18 fw-400'>
                  <span className='fw-400'>Faculty mentors:</span> Dr. Jonathan Giron, Mr. Iddo Wald, Prof, Doron Fridman (ARL, Advanced Realty Lab), Dr. Oren Zuckerman (milab, Medial Innovation Lab) Snap Challenge mentor: Dr Vinoba Vinayagamoorthy (BBC R&D)

                  </h4>

                  <p className='fs-18'>Thanks to Snap Inc, Microsoft Research and BBC R&D.</p>

                </div>
              </div>

              <div className='col-md-9 col-sm-12'>
                  <img
                    className=''
                    src={cottage03}
                    width='100%'
                  />
              </div>

                  <div className='m-4'></div>
              </div>
          </div>
      </div>
  </div>
</div>

  );
};

export default CottagePage;
