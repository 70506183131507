import React, { useState, useEffect } from 'react';
import Header from '../../shared/Header';
import VideoPlayer from './VideoCTRL';
import MainArrows from '../../shared/MainArrows';

const redirectToAstrix = () => {
  window.location.href = '/works/astrix';
};

const redirectToArrows = () => {
  window.location.href = '/works/arrows';
};



const SparkPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <div className=' p-2'>
    <MainArrows pageIndex={(9)}/>
      
      
      
    <div className='row px-4'>
        <div className='col-sm-2'></div>
        <div className='col-sm col-xs-12'>
        <div className='row mt-4'>
        <h4 className='fw-300 fs-28 capitalize'>
        Spark Eduction Program
                        </h4>
           <h4 className='fs-60 fw-600'>
           Short animated explainer for spark - a new success-based education program for skills development
           </h4>
      </div> 
      <div className='row px-3'>
                      <div className='col-12'>
                          <div className='row my-4'>
                          <VideoPlayer videoKey="Spark.mp4" videoautoplay="" controls="controls" styleguide='row col-12'/>
                          </div>
                      </div>
                  </div>
                  </div>




  <div className='mx-4 px-4'>
        <div className='row mt-4'>
        <div className='col-sm-12 col-xs-12 py-5'>

            <div className='py-3'></div>

              <div className='col-12'>
                <p className='fs-18 fw-400'>This work includes</p>
                  <div className='row'>
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Capabilities</h4>
                        <ul className='p-0'>
                          <li className='py-1'>Animated Explainer</li>
                          <li className='py-1'>Brand Design</li>
                        </ul>
                    </div>
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Softwares</h4>
                        <ul className='p-0'>
                          <li className='py-1'>After Effect</li>
                          <li className='py-1'>Illustrator</li>
                          <li className='py-1'>Photoshop</li>


                        </ul>
                    </div>
                    
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Cooperation</h4>
                        <ul className='p-0'>
                          <li className='py-1'>Project</li>

                        </ul>
                    </div>

                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Industry</h4>
                          <li className='py-1'>Eduction</li>
                          <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                          <li className='py-1'>1 Months</li>

                    </div>

                  </div>


                  <div className='m-3'></div>

                  <div className='d-flex'>
                    <h4 className='fs-18 fw-600'>Client: Spark Program</h4>
                  </div>

                  </div>
              </div>
          </div>
      </div>
    </div>
    </div>



  );
};

export default SparkPage;
