import React, { useState, useEffect } from 'react';
import Header from '../../shared/Header';

import kip01 from '../../assets/imgs/kip/kip-museum01.jpg'; 
import kip02 from '../../assets/imgs/kip/kip-museum02.jpg'; 
import kip03 from '../../assets/imgs/kip/kip-museum03.jpg'; 
import kip04 from '../../assets/imgs/kip/kip-museum04.jpg'; 
import MainArrows from '../../shared/MainArrows';


const KipMuseumPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (

    <div className=' p-2'>
    <MainArrows pageIndex={(13)}/>
      
      
      
    <div className='row px-4'>
        <div className='col-sm-2'></div>
        <div className='col-sm col-xs-12'>
            <div className='row mt-4'>
          <h4 className='fw-300 fs-28 capitalize'>
          HRI Artwork
                              </h4>
           <h4 className='fs-60 fw-600'>
           A robotic object programmed to react differently to children and adults
           </h4>
      </div> 

                    <img className='mt-4'
                      src={kip02}
                      width='100%'
                    />
      </div>



      

  

<div className='mx-4 px-4'>
  <div className='row'>
    <div className='col py-5'>
        <p className='fs-24 fw-400'>
        Our robotic object Kip presented at the Tel Aviv Museum of art! Kip is a non-humanoid robot, looks like a machine made of acrylic and electronic components, but when people interact with it, they perceive it as an entity with feelings and emotions. 
        Our academic studies show that people attribute emotional intention to objects with autonomous movement. At the museum, Kip is programmed to react differently to children and adults. When children approach, Kip leans forward towards them, making them feel appreciated as if Kip is trying to connect with them. For adults, Kip turns aside, making them feel ignored.



        </p>
    </div>
</div>
</div>




<div className='row m-0 px-5'>
            <div className='col-6 p-2'>
                <img
                  src={kip01}
                  width='100%'
                />
            </div>
            <div className='col-6 p-2'>
                <img
                  src={kip03}
                  width='100%'
                />
            </div>
            <div className='col-6 p-2'>
                <img
                  src={kip04}
                  width='100%'
                />
            </div>


          </div>







<div className='mx-4 px-4'>
<div className='row mt-4'>
  <div className='col-sm-10 col-xs-12 py-5'>
      <div className='py-3'></div>
        <div className='col-12'>
          <p className='fs-18 fw-400'>This work includes</p>
            <div className='row'>
              <div className='col'>
                
                <h4 className='fs-18 fw-600'>Capabilities</h4>

                  <ul className='p-0'>
                    <li className='py-1'>HRI Deisng</li>
                    <li className='py-1'>Robot Motion Design</li>
                    <li className='py-1'>HRI Research</li>
                  </ul>
              </div>
              <div className='col'>
                <h4 className='fs-18 fw-600'>Softwares</h4>
                  <ul className='p-0'>
                    <li className='py-1'>Blender</li>
                    <li className='py-1'>Butter Robotics</li>
                    <li className='py-1'>Raspberry Pi</li>

                  </ul>
              </div>
              
              <div className='col'>
                <h4 className='fs-18 fw-600'>Cooperation</h4>
                    <li className='py-1'>Research Fellow</li>
              </div>

              <div className='col'>
                <h4 className='fs-18 fw-600'>University</h4>
                    <li className='py-1'>milab RUNI</li>
                  <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                    <li className='py-1'>3 Months</li>
                </div>
              </div>
              
              
                </div>
            </div>
            <div className='mt-4'>
                <div className='pt-4'>
                  <h4 className='fs-18 fw-400'>
                    <span className='fw-400'> Team:</span> Andrey Grishko, Paz Roth, Tomer Leivy, Roy Cohen , Emanuel Talmasky , Ergat Takele, Prof. Oren Zuckerman, Dr. Hadas Erel 
                  </h4>
              </div>
            </div>
            <div className='m-4'></div>

        </div>
    </div>
  </div>
  </div>
  );
};

export default KipMuseumPage;
