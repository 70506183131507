import React, { useState, useEffect } from 'react';
import VideoPlayer from './VideoCTRL';
import Header from '../../shared/Header';

import kip01 from '../../assets/imgs/kip/kip-gesture.jpg'; 
import kip02 from '../../assets/imgs/kip/kip-cover-gesture.png';
import MainArrows from '../../shared/MainArrows';


const HumorousPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  const [width, setWidth] = useState(1080); 
  const height = width * (9 / 16); 

  return (
    <div className=' p-2'>
    <MainArrows pageIndex={(12)}/>
      
      
      
    <div className='row px-4'>
        <div className='col-sm-2'></div>
        <div className='col-sm col-xs-12'>
        <div className='row mt-4'>

          <h4 className='fw-300 fs-28 capitalize'>
          HRI Research
                    </h4>
           <h4 className='fs-60 fw-600'>
           Designing Non-Verbal Humorous Gestures for a Non-Humanoid Robot
           </h4>
      </div> 
      <img className='mt-4'
                          src={kip01}
                          width='100%'
                        />
      </div>



      

    <div className='mx-4 px-4'>
      <div className='row'>
        <div className='col py-5'>
            <p className='fs-24 fw-400'>
              Humor has various positive implications for our daily lives, and it has shown to improve human-robot interaction as well. To date, humor has been applied to robots that mimic human behavior thus missing out on improving interactions with the non-humanoid robots continually being deployed to our daily lives. In this work, we conducted an initial evaluation of the far-out possibility to create non-verbal humorous behavior for a robot with no human features. The robot’s humorous gestures were designed by a clown therapist, animator, and HRI expert. The initial evaluation compared participants’ responses to humorous and non-humorous robotic gestures. Our study indicates it is possible for a simple non-humanoid robot to communicate a humorous experience through gestures alone, provided the movements are carefully balanced to bring about this good humor encounter. This study’s gesture design insights can serve as first steps toward leveraging humorous behaviors in non-humanoid robots to enhance HRI.

            </p>
        </div>
    </div>
</div>





<div className='row px-3 mt-4'>
                      <div className='col-12 mt-4'>
                          <div className='row'>

                          <iframe
                              width="100%"
                              height={height}
                              src={`https://www.youtube.com/embed/Lm7cc7zP3U0`}
                              title="Arrows demo video"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                          ></iframe>

                          </div>
                      </div>
                  </div>

        
        <div className='py-4'></div>


        <div className='mx-4 px-4'>
          <div className='row'>
            <div className='col py-5'>
                <p className='fs-24 fw-400'>
                  My pivotal role in this research was to design the motion for the robotic object. The goal was challenging: finding out what motion pattern should be the right design for making humorous gestures for the robotic object.
                  Using the animation principles of staging, anticipation, timing, and pose-to-pose, I designed a set of humorous gestures with related motion graphs. Using this motion design and the research results from the participants' interviews, we explored the results of designing nonverbal humorous gestures.
                  </p>


            <div className='py-4'></div>

            <div className='row px-3 mt-4'>
                        <div className='col-4 mt-4'>
                            <div className='row'>
                              <VideoPlayer videoKey="kip_laugh.mp4" videoautoplay="autoplay" styleguide='row col-12 p-0 m-0'/>
                            </div>
                        </div>
                    </div>
           


        </div>
    </div>
</div>








  <div className='mx-4 px-4'>
    <div className='row mt-4'>
      <div className='col-sm-10 col-xs-12 py-5'>
          <div className='py-3'></div>
            <div className='col-12'>
              <p className='fs-18 fw-400'>This work includes</p>
                <div className='row'>
                  <div className='col'>
                    
                    <h4 className='fs-18 fw-600'>Capabilities</h4>

                      <ul className='p-0'>
                        <li className='py-1'>HRI Deisng</li>
                        <li className='py-1'>Robot Motion Design</li>
                        <li className='py-1'>HRI Research</li>
                      </ul>
                  </div>
                  <div className='col'>
                    <h4 className='fs-18 fw-600'>Softwares</h4>
                      <ul className='p-0'>
                        <li className='py-1'>Blender</li>
                        <li className='py-1'>Butter Robotics</li>
                        <li className='py-1'>Raspberry Pi</li>

                      </ul>
                  </div>
                  
                  <div className='col'>
                    <h4 className='fs-18 fw-600'>Cooperation</h4>
                        <li className='py-1'>Research Fellow</li>
                        <h4 className='fs-18 fw-600 pt-2'>First Author</h4>
                        <li className='py-1'>Viva Sarah Press</li>
                  </div>

                  <div className='col'>
                    <h4 className='fs-18 fw-600'>University</h4>
                        <li className='py-1'>milab RUNI</li>
                      <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                        <li className='py-1'>6 Months</li>
                    </div>
                  </div>


                  <div className='m-3'></div>
                    <div className='d-flex'>
                      <a className='' href='https://www.runi.ac.il/en/research-institutes/communication/milab/' target='blank'><h4 className='fs-18 fw-400'>Visit Website &nbsp;↗</h4></a>
                      <a className='px-4' href='https://dl.acm.org/doi/10.1145/3491101.3519924' target='blank'><h4 className='fs-18 fw-400'>Full Article &nbsp;↗</h4></a>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

  );
};

export default HumorousPage;
