import React, { useState, useEffect, useRef } from 'react';
import VideoPlayer from './VideoCTRL';
import Header from '../../shared/Header';
import arrowIcn from '../../assets/icns/arrow.svg'; 

import vayyar00 from '../../assets/imgs/vayyar/vayyar00.png'; 
import vayyar01 from '../../assets/imgs/vayyar/vayyar01.png'; 
import vayyar02 from '../../assets/imgs/vayyar/vayyar02.png'; 
import vayyar03 from '../../assets/imgs/vayyar/vayyar03.png'; 
import MainArrows from '../../shared/MainArrows';



const VayyarPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 



  const videos = [
    `https://paz-roth-videos.s3.eu-west-3.amazonaws.com/vayyar-mockup-01.mp4`,
    `https://paz-roth-videos.s3.eu-west-3.amazonaws.com/vayyar-mockup-02.mp4`,
    `https://paz-roth-videos.s3.eu-west-3.amazonaws.com/vayyar-mockup-03.mp4`,
    `https://paz-roth-videos.s3.eu-west-3.amazonaws.com/vayyar-mockup-04.mp4`,

  ];


  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRefs = useRef([]);

  useEffect(() => {
      // Preload all videos
      videoRefs.current.forEach(video => {
          if (video) {
              video.load();
          }
      });
  }, []);

  const prevSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? videos.length - 1 : prevIndex - 1));
  };

  const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex === videos.length - 1 ? 0 : prevIndex + 1));
  };


  return (
    <div className=' p-2'>
    <MainArrows pageIndex={(6)}/>
      
      
      
    <div className='row px-4'>
        <div className='col-sm-2'></div>
        <div className='col-sm col-xs-12'>
        <div className='row mt-4'>
        <h4 className='fw-300 fs-28 capitalize'>
        User Guide Animations
        </h4>
           <h4 className='fs-60 fw-600'>
           Installation Guide Animations For The caregiver assistant that never sleeps
           </h4>
      </div> 
        <img className='pt-5 img-fluid' src={vayyar00} alt=""/>
      </div>
      </div>





        <div className='mx-4 px-4'>
        <div className='row mt-4 px-sm-4'>
        <div className='col-sm-10 col-xs-12 py-5'>
          <div className='d-flex'>
            <p className='fs-24 fw-400 text-dark'>
                In this work, we built a series of short explainer loops and UX Design for the Vayyar Care application. The Vayyar Care is a small device connected to the app and emergency forces. It is placed on a wall and detects falling situations in elderly homes, saving their lives when they are alone and need emergency help.
            </p>
          </div>



          


              </div>
          </div>
      </div>








        <div className='row px-4'>
          <div className='col col-xs-12'>
          </div>
            <div className='col-sm-12 col-xs-12'></div>
            <div className='col-md col-sm-12 m-0 p-0'>
                <img
                  className='p-2'
                  src={vayyar03}
                  width='100%'
                />
            </div>
            <div className='col-md col-sm-12 m-0 p-0'>
                <img
                  className='p-2'
                  src={vayyar01}
                  width='100%'
                />
            </div>

            {/* <img className='pt-5 img-fluid' src={otterize01} alt=""/> */}

          </div>




    <div className='row px-4 py-4'>
        <div className="slider">
          <button className="slider-button prev" onClick={prevSlide}>
            <img src={arrowIcn} className='rotate-180' width={'35px'} alt="Previous" />
          </button>
          <div className="slider-video">
            {videos.map((video, index) => (
              <video
                key={index}
                src={video}
                className={index === currentIndex ? 'active' : 'inactive'}
                muted
                autoPlay
                loop
              />
            ))}
          </div>
          <button className="slider-button next" onClick={nextSlide}>
            <img src={arrowIcn} width={'35px'}  alt="Next" /> 
          </button>
        </div>
    </div>


    <div className='mx-4 px-4'>
      <div className='row mt-4'>
        <div className='col-sm-12 col-xs-12 py-5'>
              <div className='col-12'>
                <p className='fs-18 fw-400'>This work includes</p>
                  <div className='row'>
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Capabilities</h4>
                        <ul className='p-0'>
                          <li className='py-1'>2D Animation</li>
                          <li className='py-1'>UI design</li>
                          <li className='py-1'>UX Review</li>

                        </ul>
                    </div>
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Softwares</h4>
                        <ul className='p-0'>
                          <li className='py-1'>After Effect</li>
                          <li className='py-1'>Illustrator</li>
                          <li className='py-1'>Photoshop</li>
                          <li className='py-1'>Lottie</li>
                        </ul>
                    </div>
                    
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Cooperation</h4>
                        <ul className='p-0'>
                          <li className='py-1'>Project</li>

                        </ul>
                    </div>

                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Industry</h4>
                          <li className='py-1'>Technology, Healthcare</li>
                          <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                          <li className='py-1'>2 Months</li>

                    </div>

                  </div>


                  <div className='mt-4'>
                    <div className='d-flex'>
                      <h4 className='fs-18 fw-600'>Client: Vayyar Care</h4>
                        <a className='px-2' href='https://vayyar.com/care/' target='blank'><h4 className='fs-18 fw-400 px-4'>Visit Website &nbsp;↗</h4></a>
                    </div>
                  </div>
                  <div className='m-4'></div>


                  </div>
              </div>
          </div>
      </div>
  </div>


  );
};

export default VayyarPage;


