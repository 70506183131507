import React, { useState, useEffect } from 'react';
import VideoPlayer from './VideoCTRL';
import Header from '../../shared/Header';

import ennocure01 from '../../assets/imgs/ennocure/app_mockup_01.png';
import ennocure02 from '../../assets/imgs/ennocure/pad_demo_image.png'; 
import ennocure03 from '../../assets/imgs/ennocure/pad_on_arm.png'; 
import MainArrows from '../../shared/MainArrows';





const EnnocurePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <div className=' p-2'>
    <MainArrows pageIndex={(8)}/>
      
      
    <div className='row px-4'>
        <div className='col-sm-2'></div>
        <div className='col-sm col-xs-12'>
        <div className='row mt-4'>
        <h4 className='fw-300 fs-28 capitalize'>
        Product Design, Animation
                </h4>
           <h4 className='fs-60 fw-600'>
           Ennocure provides a Personalized Wound Therapy Kit - Anywhere You Need It
           </h4>
      </div> 
      <div className='row px-3 mt-4'>
                        <div className='col-12 mt-4'>
                            <div className='row'>
                              <VideoPlayer videoKey="ennocure01.mp4" videoautoplay="autoplay" styleguide='row col-12 p-0 m-0'/>
                            </div>
                        </div>
                    </div>
                  </div>
                  </div>




      <div className='mx-4 px-4'>
        <div className='row'>
          <div className='col py-5'>
            <div className='d-flex'>
              <p className='fs-24 fw-400'>
                  
              For their next phase funding round, we worked together to create a set of visuals and animations demonstrating the power of Ennocure's healing therapy kit. The visuals included a demonstration of how to use the kit and explanations of its values for patients and healthcare providers.
              </p>
            </div>
          </div>
        </div>
      </div>



        <div className='row m-0 px-5'>
            <div className='col p-2'>
                <img
                  src={ennocure01}
                  width='100%'
                />
            </div>
            <div className='col p-2'>
                <img
                  src={ennocure02}
                  width='100%'
                />
            </div>
            <div className='col p-2'>
                <img
                  src={ennocure03}
                  width='100%'
                />
            </div>


          </div>

{/* 
          <div className='row my-5 mx-0 px-5'>
            <div className='d-flex mb-5'>
              <p className='fs-24 fw-400'>
              </p>
            </div>
          </div> */}

{/* 



      <div className='row px-5 m-0'>
          <div className='position-relative'>
                <div className='row position-absolute top-50 translate-middle-y w-100'>
                  <div className='col-6 d-flex justify-content-start'>
                    <img
                      className='p-3 rotate-180 pointer'
                      onClick={prevVideo}
                      src={arrowIcn}
                      width='20%'
                      alt='Previous'
                    />
                  </div>
                  <div className='col-6 d-flex justify-content-end'>
                    <img
                      className='p-3 pointer'
                      onClick={nextVideo}
                      src={arrowIcn}
                      width='20%'
                      alt='Next'
                    />
                  </div>
                </div>

                <div className='col-12'>
                        <video
                          className="video"
                          src={getVideoUrl(videoNames[currentVideoIndex])}
                          autoPlay='autoplay'
                          muted
                          loop
                          style={{ maxWidth: '100%' }}
                        />
                      </div>
            </div>
        </div> */}

{/* 

        <div className='row px-5 m-0'>
          <div className='col-12'>
              <video
                className="video"
                src={getVideoUrl(videoNames[0])}
                autoPlay='autoplay'
                muted
                loop
                style={{ maxWidth: '100%' }}
              />
          </div>
        </div>

        <div className='row px-5 m-0'>
          <div className='col-12'>
              <video
                className="video"
                src={getVideoUrl(videoNames[1])}
                autoPlay='autoplay'
                muted
                loop
                style={{ maxWidth: '100%' }}
              />
          </div>
        </div>
        <div className='row px-5 m-0'>
          <div className='col-12'>
              <video
                className="video"
                src={getVideoUrl(videoNames[2])}
                autoPlay='autoplay'
                muted
                loop
                style={{ maxWidth: '100%' }}
              />
          </div>
        </div>
        <div className='row px-5 m-0'>
          <div className='col-12'>
              <video
                className="video"
                src={getVideoUrl(videoNames[3])}
                autoPlay='autoplay'
                muted
                loop
                style={{ maxWidth: '100%' }}
              />
          </div>
        </div> */}

  
    <div className='mx-4 px-4'>
      <div className='row mt-4'>
        <div className='col-sm-10 col-xs-12 py-5'>
            <div className='py-3'></div>
              <div className='col-12'>
                <p className='fs-18 fw-400'>This work includes</p>
                  <div className='row'>
                    <div className='col'>

                      <h4 className='fs-18 fw-600'>Capabilities</h4>

                        <ul className='p-0'>
                          <li className='py-1'>Product Design</li>
                          <li className='py-1'>Web Design</li>
                          <li className='py-1'>Web Animation</li>
                          <li className='py-1'>UX Review</li>
                        </ul>
                    </div>
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Softwares</h4>
                        <ul className='p-0'>
                          <li className='py-1'>Maya</li>
                          <li className='py-1'>After Effect</li>
                          <li className='py-1'>Photoshop</li>
                          <li className='py-1'>Lottie</li>
                        </ul>
                    </div>
                    
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Cooperation</h4>
                        <ul className='p-0'>
                          <li className='py-1'>Project</li>
                        </ul>
                    </div>

                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Industry</h4>
                          <li className='py-1'>Healthcare</li>
                          <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                          <li className='py-1'>2 Months, ongoing</li>
                      </div>
                    </div>


                <div className='m-3'></div>
                  <div className='d-flex'>
                    <h4 className='fs-18 fw-600'>Client: Ennocure</h4>
                    <a className='px-2' href='https://ennocure.com' target='blank'><h4 className='fs-18 fw-400 px-4'>Visit Website &nbsp;↗</h4></a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>


  );
};


export default EnnocurePage;


