import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.svg'; 
import menu from '../assets/icns/menu.svg'; 
import Menu from './Menu';




const MainArrows = ({ pageIndex }) => {


  
  const handleNextPage = () => {
    const pageNumber = pageIndex + 1;
    handlePage(pageNumber)
  }

    
  const handleBackPage = () => {
    const pageNumber = pageIndex - 1;
    handlePage(pageNumber)
  }

  const handlePage = (pageNumber) => {

    if (pageNumber === 1){
      window.location.href = '/navina';
    }
    else if (pageNumber === 2){
      window.location.href = '/arrows';
    }
    else if (pageNumber === 3){
      window.location.href = '/otterize';
    }
    else if (pageNumber === 4){
      window.location.href = '/clariter';
    }
    else if (pageNumber === 5){
      window.location.href = '/cottage';
    }
    else if (pageNumber === 6){
      window.location.href = '/vayyar';
    }
    else if (pageNumber === 7){
      window.location.href = '/astrix';
    }
    else if (pageNumber === 8){
      window.location.href = '/ennocure';
    }
    else if (pageNumber === 9){
      window.location.href = '/spark';
    }
    else if (pageNumber === 10){
      window.location.href = '/komodor';
    }
    else if (pageNumber === 11){
      window.location.href = '/cyber-ball';
    }
    else if (pageNumber === 12){
      window.location.href = '/humorous-gestures';
    }
    else if (pageNumber === 13){
      window.location.href = '/kip-museum';
    }
    else if (pageNumber === 14){
      window.location.href = '/virtual-exhibition';
    }
    else {
      window.location.href = '/';
    }
  };





  return (
    <div className=''>
      <div onClick={handleBackPage} className="floating-arrow left-arrow">
            &#8592; {/* Unicode for left arrow */}
          </div>
          <div onClick={handleNextPage} className="floating-arrow right-arrow">
            &#8594; {/* Unicode for right arrow */}
          </div>
  </div>

  



  );
}
  
  export default MainArrows;
  