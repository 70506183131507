import React, { useState, useEffect } from 'react';
import Header from '../../shared/Header';

import cyber03 from '../../assets/imgs/kip/cyber_ball_installation.png'; 
import cyber02 from '../../assets/imgs/kip/cyber_ball_participant.png';
import cyber01 from '../../assets/imgs/kip/cyber_ball_robots.png'; 
import MainArrows from '../../shared/MainArrows';


const CyberBallPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  const [width, setWidth] = useState(1080); 
  const height = width * (9 / 16); 

  return (
    <div className=' p-2'>
    <MainArrows pageIndex={(11)}/>
      
      
      
    <div className='row px-4'>
        <div className='col-sm-2'></div>
        <div className='col-sm col-xs-12'>
        <div className='row mt-4'>
          <h4 className='fw-300 fs-28 capitalize'>
            HRRI Research
          </h4>
           <h4 className='fs-60 fw-600'>
           Excluded by Robots - A Human-Robot-Robot Interaction study
           </h4>
      </div> 
          <img className='mt-4'
              src={cyber01}
              width='100%'
            />
      </div>




    <div className='mx-4 px-4'>
      <div className='row'>
        <div className='col py-5'>
            <p className='fs-24 fw-400'>
              Interest in integrating social and personal robots in our daily lives has been growing over the years. It is believed that various autonomous devices will be common in homes and workplaces in the near future, interacting with humans and each other. In this study, we aimed to explore and compare situations that involve different levels of involvement of humans in Robot-Robot interactions, ranging from excluding the human from the interaction to include and over-including the human in the interaction. We test the effect on the human's emotions and perception of the devices.
            </p>
            <p className='fs-24 fw-400'>
              In the study, we used a well-known paradigm from social psychology called CyberBall. We designed a catch (throwing a ball to one another) interaction between one person and two non-humanoid robots. The proportion of tosses from the robot's towards the participant will determine the level of the human's involvement in the interaction. We measured the involvement's influence on the participant's feelings related to ostracism, mood, and perception of the robots. 
            </p>

            <p className='fs-24 fw-400'>
              The article was formally accepted to the International Conference on Human-Robot Interaction (HRI 2021) and got an average score of 4.8 out of 5!
            </p>
        </div>
    </div>
</div>





<div className='row px-3 mt-4'>
                      <div className='col-12 mt-4'>
                          <div className='row'>

                          <iframe
                              width="100%"
                              height={height}
                              src={`https://www.youtube.com/embed/yfCs6f5UT6Y`}
                              title="Arrows demo video"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                          ></iframe>

                          </div>
                      </div>
                  </div>


<div className='mx-4 px-4'>
      <div className='row'>
        <div className='col py-5'>

            <p className='fs-24 fw-600'>
            My role - Motion Designer
            </p>

            <p className='fs-24 fw-400'>
            My team included:
            A project manager.
            Two psychology students.
            A computer science student.
            An industrial designer.
            Academic advisors.
            A motion designer [me].
            </p>

              <p className='mt-4 pt-4 fs-24 fw-600'>
                Main Responsibilities
              </p>
              <p className='fs-24 fw-400'>
                My primary responsibilities in this research were to plan and create the Motion Design for the three robotic objects of the study: Kip, Gimi, and the Arm. Together with the research team, we developed and designed the system, the experiment setup, and a simple UI control for the user. The research includes different methods, such as semi-structured interviews and anonymous multi-choice questionnaires. Collecting data, interview transcripts, and analyzing qualitative data using thematic coding methods
              </p>


            <div className='py-4'></div>

              <div className='row '>
                  <div className='col-12 p-2'>
                        <img
                          src={cyber02}
                          width='100%'
                        />
                    </div>
                    <div className='col-12 p-2'>
                        <img
                          src={cyber03}
                          width='100%'
                        />
                    </div>
                  </div>

            <div className='py-4'></div>

            <p className='fs-24 fw-400'>
              I planned to create a golf-playing style experience for the motion design of the robots; for that, each robot got a motion that reminded the movement of hitting a golf ball. That was a challenging mission due to the structure of the robots. Still, in the end, I got into that motion design that also affected the participant's feeling of excluded when the robots started to play between themselves.
              The robots played the animations using a blender to Raspberry PI interface called 'Butter.'
            </p>
            <p className='fs-24 fw-400'>
              To make the final motion design decisions, we interviewed 40 participants. Some of the questions we asked were:
              Describe what happened in this experience.
              How did you feel during the experiment?
              Did you think that the game was fair?
              What role/part did you take in the game?
              Some of the participants before you described their experience as positive, and others described it negatively. What is your opinion?
            </p>
            <p className='fs-24 fw-400'>
              This project helped me understand the process of conducting research, user testing, and how to make a motion and experience design on the side of research when every keyframe selected should have a research justification.
            </p>
        </div>
    </div>
</div>








  <div className='mx-4 px-4'>
    <div className='row mt-4'>
      <div className='col-sm-10 col-xs-12 py-5'>
          <div className='py-3'></div>
            <div className='col-12'>
              <p className='fs-18 fw-400'>This work includes</p>
                <div className='row'>
                  <div className='col'>
                    
                    <h4 className='fs-18 fw-600'>Capabilities</h4>

                      <ul className='p-0'>
                        <li className='py-1'>HRRI Design</li>
                        <li className='py-1'>Robot Motion Design</li>
                        <li className='py-1'>HRRI Research</li>
                      </ul>
                  </div>
                  <div className='col'>
                    <h4 className='fs-18 fw-600'>Softwares</h4>
                      <ul className='p-0'>
                        <li className='py-1'>Blender</li>
                        <li className='py-1'>Butter Robotics</li>
                        <li className='py-1'>Raspberry Pi</li>

                      </ul>
                  </div>
                  
                  <div className='col'>
                    <h4 className='fs-18 fw-600'>Cooperation</h4>
                      <ul className='p-0'>
                        <li className='py-1'>Research Fellow</li>
                      </ul>
                  </div>

                  <div className='col'>
                    <h4 className='fs-18 fw-600'>University</h4>
                        <li className='py-1'>milab RUNI</li>
                        <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                        <li className='py-1'>6 Months</li>
                    </div>
                  </div>


                  <div className='m-3'></div>
                    <div className='d-flex'>
                      <a className='' href='https://www.runi.ac.il/en/research-institutes/communication/milab/' target='blank'><h4 className='fs-18 fw-400'>Visit Website &nbsp;↗</h4></a>
                      <a className='px-4' href='https://dl.acm.org/doi/10.1145/3434073.3444648' target='blank'><h4 className='fs-18 fw-400'>Full Article &nbsp;↗</h4></a>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

  );
};

export default CyberBallPage;
