import React, { useState, useEffect } from 'react';
import Header from '../../shared/Header';

import VideoPlayer from './VideoCTRL';
import arrowIcn from '../../assets/icns/arrow.svg'; 

import clariter00 from '../../assets/imgs/clariter/clariter00.png'; 
import clariter01 from '../../assets/imgs/clariter/clariter01.png'; 
import clariter02 from '../../assets/imgs/clariter/clariter02.png'; 
import clariter03 from '../../assets/imgs/clariter/clariter03.png'; 
import clariter04 from '../../assets/imgs/clariter/clariter04.png'; 
import clariter05 from '../../assets/imgs/clariter/clariter05.png'; 
import MainArrows from '../../shared/MainArrows';



const ClariterPage = () => {

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []); 

  
  const videoNames = ['navina_mockups0002.mp4', 'navina_mockups0003.mp4', 'navina_mockups0004.mp4', 'navina_mockups0005.mp4']; // Add other video names as needed
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const getVideoUrl = (videoName) => {
    return `https://paz-roth-videos.s3.eu-west-3.amazonaws.com/${videoName}`;
  };

  const nextVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex === 0 ? videoNames.length - 1 : prevIndex - 1));
  };

  const prevVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex === videoNames.length - 1 ? 0 : prevIndex + 1));
  };



  return (
    <div className=' p-2'>
      <MainArrows pageIndex={(4)}/>
        
        
        
      
      <div className='row px-4'>
        <div className='col-sm-2'></div>
        <div className='col-sm col-xs-12'>
        <div className='row mt-4'>
          <h4 className='fw-300 fs-28 capitalize'>Animated Explainer Video | Brand Design</h4>
             <h4 className='fs-60 fw-600'>
                    Video, Design And Brand Materials To Explain The Clean Slate Solution Of Clariter
                                        </h4>

                <img className='pt-5 img-fluid' src={clariter02} alt=""/>
        </div> 
        </div>

        </div>



        <div className='row m-0 px-4'>
            <div className='col-6 p-2'>
                <img
                  src={clariter03}
                  width='100%'
                />
            </div>
            <div className='col-6 p-2'>
                <img
                  src={clariter04}
                  width='100%'
                />
            </div>
            <div className='col-6 p-2'>
                <im
                  src={clariter05}
                  width='100%'
                />
            </div>

        
        <div className='mx-4 px-4'>
        <div className='row mt-4'>
        <div className='col-sm-10 col-xs-12 py-5'>


            <div className='py-3'></div>

              <div className='col-12'>
                <p className='fs-18 fw-400'>This work includes</p>
                  <div className='row'>
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Capabilities</h4>
                        <ul className='p-0'>
                          <li className='py-1'>Web Animation</li>
                          <li className='py-1'>Brand Design</li>
                          <li className='py-1'>Explainer Video</li>
                          <li className='py-1'>Web Animation</li>
                        </ul>
                    </div>
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Softwares</h4>
                        <ul className='p-0'>
                          <li className='py-1'>Maya 3D</li>
                          <li className='py-1'>After Effect</li>
                          <li className='py-1'>Illustrator</li>
                          <li className='py-1'>Photoshop</li>
                        </ul>
                    </div>
                    
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Cooperation</h4>
                        <ul className='p-0'>
                          <li className='py-1'>Project</li>

                        </ul>
                    </div>

                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Industry</h4>
                          <li className='py-1'>Clean Tech</li>
                          <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                          <li className='py-1'>2.5 Months</li>

                    </div>

                  </div>


                  <div className='m-3'></div>

                  <div className='d-flex'>
                    <h4 className='fs-18 fw-600'>Client: Clariter</h4>
                    {/* <a className='px-2' href='https://navina.ai' target='blank'><h4 className='fs-18 fw-400 px-4'>Visit Website &nbsp;↗</h4></a> */}

                  </div>

                  </div>
              </div>
          </div>
      </div>
  </div>
  </div>


  );
};

export default ClariterPage;
