import React from 'react';
import Header from '../shared/Header';

const CVPage = () => {
  return (
    <div>
      <Header/>
      <div className='container'>
        <div className='header-height'></div>
         <div className='h-100 row d-flex align-items-start'>
            <div className='col-9'>
              <div className='fs-2 capitalize'>
CV                </div>
          </div>
         </div>
      </div>
    </div>
  );
};

export default CVPage;
