import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './shared/Header';


import CVPage from './pages/CV';
import AboutPage from './pages/About';
import IndexPage from './pages/Index';
import ContactPage from './pages/Contact';
import NavinaPage from './pages/works/Navina';
import HomePage from './pages/Home';
import AstrixPage from './pages/works/Astrix';
import OtterizePage from './pages/works/Otterize';
import CottagePage from './pages/works/Cottage';
import VayyarPage from './pages/works/Vayyar';
import EnnocurePage from './pages/works/Ennocure';
import SparkPage from './pages/works/Spark';
import KomodorPage from './pages/works/Komodor';
import CyberBallPage from './pages/works/Cyber';
import HumorousPage from './pages/works/Humorous';
import KipMuseumPage from './pages/works/KipMuseum';
import ArrowsPage from './pages/works/Arrows';
import ClariterPage from './pages/works/Clariter';
import SideHeader from './shared/SideHeader';
import GeoxITCPage from './pages/works/geoxITC';


const App = () => {

  return (
    <div className="light-background">

      <BrowserRouter>
              <Header className="position-absolute"/>
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/CV" element={<CVPage />} />
          <Route path="/contact" element={<ContactPage />} />

          <Route path="/navina" element={<NavinaPage />} />
          <Route path="/geoxITC" element={<GeoxITCPage />} />
          <Route path="/clariter" element={<ClariterPage />} />
          <Route path="/arrows" element={<ArrowsPage />} />
          <Route path="/otterize" element={<OtterizePage />} />
          <Route path="/cottage" element={<CottagePage />} />
          <Route path="/vayyar" element={<VayyarPage />} />
          <Route path="/astrix" element={<AstrixPage />} />
          <Route path="/ennocure" element={<EnnocurePage />} />
          <Route path="/spark" element={<SparkPage />} />
          <Route path="/komodor" element={<KomodorPage />} />
          <Route path="/cyber-ball" element={<CyberBallPage />} />
          <Route path="/humorous-gestures" element={<HumorousPage />} />
          <Route path="/kip-museum" element={<KipMuseumPage />} />

        </Routes>
      </BrowserRouter>


      



      </div>
  );
}

export default App;
