import React, { useState, useEffect } from 'react';
import Header from '../../shared/Header';

import VideoPlayer from './VideoCTRL';

import astirx01 from '../../assets/imgs/astrix/astrix01.png'; 
import astirx02 from '../../assets/imgs/astrix/astrix02.png'; 
import astirx03 from '../../assets/imgs/astrix/astrix03.png'; 
import astirx04 from '../../assets/imgs/astrix/astrix04.png'; 
import MainArrows from '../../shared/MainArrows';


const AstrixPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 


  return (
    <div className=' p-2'>
    <MainArrows pageIndex={(7)}/>
      
      
    <div className='row px-4'>
        <div className='col-sm-2'></div>
        <div className='col-sm col-xs-12'>
        <div className='row mt-4'>
              <h4 className='fw-300 fs-28 capitalize'>Animated Explainer | Brand Identity</h4>
                  <h4 className='fs-60 fw-600'>
                  Explaining a cyber security product provided by Astrix Security
                  </h4>
                  <p className='fs-24 fw-400 mt-4'>
                  Astrix security platform enables you to control and secure API keys, OAuth apps, service accounts and other NHIs, protecting your business and engineering environments from unauthorized access.
                  </p>
            </div>
          </div>
        </div>





  <div className='mt-4 px-4'>
    <div className='row'>
      <div className=''>
        <VideoPlayer  styleguide='row p-0 m-0' videoKey="Astrix.mp4" videoautoplay="" controls="controls"  />
      </div>
    </div>
  </div>





  <div className='mx-4 px-4'>
        <div className='row mt-4 px-5'>
          <div className='col-sm-10 col-xs-12 py-5'>
              <div className=''>
                <h5 className='pb-4 fw-600 fs-20'>Design</h5>
                    <p className='fs-24 fw-400'>

                    </p>
                </div>
              </div>
          </div>
      </div>


  <div className='mx-4 px-4'>
    <div className='row'>
            <div className='col-6 p-2'>
                <img
                  src={astirx01}
                  width='100%'
                />
            </div>
            <div className='col-6 p-2'>
                <img
                  src={astirx02}
                  width='100%'
                />
            </div>
            <div className='col-6 p-2'>
                <img
                  src={astirx03}
                  width='100%'
                />
            </div>
            <div className='col-6 p-2'>
                <img
                  src={astirx04}
                  width='100%'
                />
            </div>
            <div className='col-6 p-2'>
  
            </div>

          <div className='row px-3'>
              <div className='col-12'>
                  <div className='row'>
                    {/* <VideoPlayer videoKey="mockups0001.mp4" videoautoplay="autoplay" styleguide='row col-12 p-0 m-0'/> */}
                  </div>
              </div>
          </div>
      </div>
    </div>


  
  <div className='mx-4 px-4'>
        <div className='row mt-4'>
        <div className='col-sm-12 col-xs-12 py-5'>

            <div className='py-3'></div>

              <div className='col-12'>
                <p className='fs-18 fw-400'>This work includes</p>
                  <div className='row'>
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Capabilities</h4>
                        <ul className='p-0'>
                          <li className='py-1'>3D Animation</li>
                          <li className='py-1'>Web Animation</li>
                          <li className='py-1'>Product Design</li>
                          <li className='py-1'>Brand Design</li>
                        </ul>
                    </div>
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Softwares</h4>
                        <ul className='p-0'>
                          <li className='py-1'>Maya</li>
                          <li className='py-1'>After Effect</li>
                          <li className='py-1'>Photoshop</li>
                        </ul>
                    </div>
                    
                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Cooperation</h4>
                        <ul className='p-0'>
                          <li className='py-1'>Project</li>

                        </ul>
                    </div>

                    <div className='col'>
                      <h4 className='fs-18 fw-600'>Industry</h4>
                          <li className='py-1'>Cyber Security</li>
                          <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                          <li className='py-1'>2 Months</li>

                    </div>

                  </div>


                  <div className='m-3'></div>

                  <div className='d-flex'>
                    <h4 className='fs-18 fw-600'>Client: Astrix Security</h4>
                    <a className='px-2' href='https://astrix.security' target='blank'><h4 className='fs-18 fw-400 px-4'>Visit Website &nbsp;↗</h4></a>

                  </div>

                  </div>
              </div>
          </div>
      </div>



</div>
  );
};

export default AstrixPage;
